import './style.scss';

const Hero = () => {
	return (
		<div className="hero">
			<div className="name">
				<span>Hello, I am</span>
				<h1>Hudu.</h1>
			</div>
			<h2>I build software products for the web.</h2>
			<h3>
				A passionate web developer working on building scalable web apps.
				<br />
				Currently, I&apos;m focused on building accessible and design-friendly UI/UX with React.
			</h3>
		</div>
	);
};

export default Hero;
