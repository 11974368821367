// Internal
import { Hero, About, Resume, Email, Header, Footer, Projects, Form } from './components';

// External
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './global.scss';

const queryClient = new QueryClient();

const Main = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<Header />
			<main>
				<Hero />
				<About />
				<Projects />
				<Resume />
				<Form />
				<Email />
			</main>
			<Footer />
		</QueryClientProvider>
	);
};

// Render
const div = document.getElementById('root');
if (div) {
	const root = ReactDOM.createRoot(div);
	root.render(
		<React.StrictMode>
			<Main />
		</React.StrictMode>
	);
}
