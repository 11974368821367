import { useState } from 'react';
import './style.scss';

type FormSubmission = {
	name: string;
	email: string;
	message: string;
};

const debounce = (callback: (event: any) => void, timeout = 700) => {
	let timer: number;

	return (...args: any) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			callback.apply(this, args);
		}, timeout);
	};
};

const useFormSubmissionQuery = async ({ name, email, message }: FormSubmission) => {
	const response = await fetch('https://formsubmit.co/ajax/32d07d482dd09699fea346d1ffce5899', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify({
			name: name,
			email: email,
			message: message,
		}),
	});

	return response.json();
};

const validateInput = (type: 'name' | 'email' | 'message', value: string) => {
	switch (type) {
		case 'name':
			if (!value || value.length < 1) {
				return 'Please enter name';
			}

			if (value.length > 24) {
				return 'Name cannot exceed 24 characters';
			}
			break;
		case 'email':
			const validateRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (value) {
				validateRegex.test(value);
				return 'Please enter a valid email address';
			} else {
				return 'Please enter an email address';
			}
		case 'message':
			if (!value || value.length < 1) {
				return 'Please enter a message';
			}
			break;
		default:
			break;
	}
};

const Form = () => {
	const [submitState, setSubmitState] = useState('NEW');
	const [inputFieldMessage, setInputFieldMessage] = useState({ name: '', email: '', message: '' });

	const handleSubmit: (event: any) => void = (event) => {
		event.preventDefault();
		setSubmitState('SUBMITTING');

		const form = event.currentTarget;
		if (form) {
			useFormSubmissionQuery({
				name: form.name.value,
				email: form.email.value,
				message: form.message.value,
			}).then((response) => {
				if (response.success === 'true') {
					setSubmitState('SUCCESS');
				} else {
					console.log(response);
					setSubmitState('ERROR');
				}
			});
		}
	};

	const handleChange = (event: any) => {
		const { id, value } = event.target;

		validateInput(id, value);

		setInputFieldMessage((prevState) => ({
			...prevState,
			[id]: validateInput(id, value),
		}));
	};

	const debounceOnChange = debounce(handleChange, 7000);

	return (
		<div className="form">
			{submitState === 'NEW' && (
				<form onSubmit={handleSubmit} className="form_outline">
					{inputFieldMessage.name && <p className="error">{inputFieldMessage.name}</p>}
					<label htmlFor="name" className="label">
						Full&nbsp;Name
					</label>
					<input
						className="input"
						id="name"
						type="text"
						name="Full&nbsp;Name"
						placeholder="Full Name"
						onChange={debounceOnChange}
						required
					/>

					{inputFieldMessage.email && <p className="error">{inputFieldMessage.email}</p>}
					<label htmlFor="email">Email</label>
					<input
						className="input"
						id="email"
						type="email"
						name="email"
						placeholder="example@gmail.com"
						onChange={debounceOnChange}
						required
					/>
					{inputFieldMessage.message && <p className="error">{inputFieldMessage.message}</p>}

					<label htmlFor="message">Message</label>
					<textarea
						className="textarea"
						name="message"
						id="message"
						rows={4}
						cols={30}
						placeholder="your message"
						onChange={handleChange}
						required
					/>
					<button type="submit" className="btn">
						<h3>Submit</h3>
					</button>
				</form>
			)}
			{submitState === 'SUBMITTING' && <h1>Loading...</h1>}
			{submitState === 'SUCCESS' && (
				<div className="form_popup">
					<h2>Form Submitted Successfully!</h2>
					<button onClick={() => setSubmitState('NEW')} className="btn_modal">
						close
					</button>
				</div>
			)}

			{submitState === 'ERROR' && <h1>Oops, something went wrong... my bad</h1>}
		</div>
	);
};

export default Form;
