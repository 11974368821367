interface NavProps {
	toggle?: () => void;
}

function Nav({ toggle }: NavProps) {
	return (
		<ul>
			<li>
				<a onClick={toggle} href="/#about">
					About
				</a>
			</li>
			<li>
				<a onClick={toggle} href="/#projects">
					Projects
				</a>
			</li>
			<li>
				<a onClick={toggle} href="/#resume">
					Resume
				</a>
			</li>
			<li>
				<a onClick={toggle} href="/#email">
					Email
				</a>
			</li>
		</ul>
	);
}

export default Nav;
