import { Divide } from 'hamburger-react';
import Nav from './nav';
import { useState, useEffect } from 'react';

const NavMobile = () => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (open) {
			document.body.classList.add('blur');
		} else {
			document.body.classList.remove('blur');
		}
	}, [open]);

	return (
		<div className="hamburger-menu">
			<Divide toggle={setOpen} toggled={open} />
			{open && (
				<div className="mobile-nav">
					<Nav toggle={() => setOpen(false)} />
				</div>
			)}
		</div>
	);
};

export default NavMobile;
