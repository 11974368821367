import './style.scss';
import email from '../image/email.jpg';

const Email = () => {
	return (
		<div className="email" id="email">
			<h1 className="section-head">Email</h1>
			<a href="mailto:ahmedhudu21@gmail.com" rel="noopener noreferrer" target={'blank'}>
				<img src={email} alt="email" className="email" />
			</a>
		</div>
	);
};

export default Email;
