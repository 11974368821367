import './style.scss';
import covid from '../image/covid.png';
import weather from '../image/weather.png';
import bookapi from '../image/bookapi.png';
import office from '../image/office.png';
import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';
import VisibilityIcon from './view';

const Projects = () => {
	const [isFlipped, setIsFlipped] = useState('');

	return (
		<div className="projects" id="projects">
			<h1 className="section-head">Projects</h1>
			<div className="project-list">
				<ReactCardFlip isFlipped={isFlipped === 'covid'} flipDirection="horizontal">
					<div
						className="covid"
						onMouseEnter={() => setIsFlipped('covid')}
						onClick={() => setIsFlipped('covid')}
					>
						<img className="image" src={covid} alt="covid app img." />
					</div>
					<div
						className="covid_back"
						id="back"
						onMouseLeave={() => setIsFlipped('')}
						onClick={() => setIsFlipped('')}
					>
						<p>
							API fetch of covid infected countries, created to keep track of number of active,
							recovery and death cases per geographical region as well as preventive measures.
						</p>
						<div className="misc">
							<span className="stack">
								<b>Technologies used</b>
							</span>
							<ul>
								<li>Javascript/Ajax</li>
								<li>API</li>
								<li>HTML</li>
								<li>CSS</li>
							</ul>
							<button>
								<a
									target={'blank'}
									href="https://hudufodio.github.io/Covid-tracker/"
									rel="noreferrer"
								>
									<span className="view">view project</span>
									<VisibilityIcon />
								</a>
							</button>
						</div>
					</div>
				</ReactCardFlip>
				<ReactCardFlip isFlipped={isFlipped === 'weather'} flipDirection="horizontal">
					<div
						className="wea"
						onMouseEnter={() => setIsFlipped('weather')}
						onClick={() => setIsFlipped('weather')}
					>
						<img className="image" src={weather} alt="weather app img." />
					</div>
					<div
						className="wea_back"
						id="back"
						onMouseLeave={() => setIsFlipped('')}
						onClick={() => setIsFlipped('')}
					>
						<p>A weather app that fetches climatic data of a specific location or region.</p>
						<div className="misc">
							<span className="stack">
								<b>Technologies used</b>
							</span>
							<ul>
								<li>Javascript</li>
								<li>API</li>
								<li>HTML</li>
								<li>CSS</li>
							</ul>
							<button>
								<a
									target={'_blank'}
									href="https://hudufodio.github.io/weatherApiProject/"
									rel="noreferrer"
								>
									<span className="view">view project</span>
									<VisibilityIcon />
								</a>
							</button>
						</div>
					</div>
				</ReactCardFlip>
				<ReactCardFlip isFlipped={isFlipped === 'office'} flipDirection="horizontal">
					<div
						className="office"
						onMouseEnter={() => setIsFlipped('office')}
						onClick={() => setIsFlipped('office')}
					>
						<img className="image" src={office} alt="office-lady img." />
					</div>
					<div
						className="office_back"
						id="back"
						onMouseLeave={() => setIsFlipped('')}
						onClick={() => setIsFlipped('')}
					>
						<p>
							A product landing page that intends to advocate and promote e-commerce, metaverse and
							internet of things(MOTs & IOTs) for potential clients.
						</p>
						<div className="misc">
							<span className="stack">Technologies used</span>
							<ul>
								<li>CSS/Sass</li>
								<li>HTML</li>
							</ul>
							<button>
								<a
									target={'blank'}
									href="https://hudufodio.github.io/New-project-f5/"
									rel="noreferrer"
								>
									<span className="view">view project</span>
									<VisibilityIcon />
								</a>
							</button>
						</div>
					</div>
				</ReactCardFlip>
				<ReactCardFlip isFlipped={isFlipped === 'bookapi'} flipDirection="horizontal">
					<div
						className="drum"
						onMouseEnter={() => setIsFlipped('bookapi')}
						onClick={() => setIsFlipped('bookapi')}
					>
						<img className="image" src={bookapi} alt="online bookshop img." />
					</div>
					<div
						className="bookapi_back"
						id="back"
						onMouseLeave={() => setIsFlipped('')}
						onClick={() => setIsFlipped('')}
					>
						<p>
							An online bookshop that facilitates book search, purchase and as well reading online,
							simple interface and excellent user experience.
						</p>

						<div className="misc">
							<span className="stack">Technologies used</span>
							<ul>
								<li>Typescript/Javascript</li>
								<li>React</li>
								<li>API</li>
								<li>Axios</li>
								<li>HTML</li>
								<li>Sass</li>
							</ul>
							<button>
								<a
									target={'blank'}
									href="https://github.com/Hudufodio/online-bookshop"
									rel="noreferrer"
								>
									<span className="view">view project</span>
									<VisibilityIcon />
								</a>
							</button>
						</div>
					</div>
				</ReactCardFlip>
			</div>
		</div>
	);
};

export default Projects;
