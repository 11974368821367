import hod from '../image/hod.jpg';
import './style.scss';

const About = () => {
	return (
		<div className="about" id="about">
			<h1 className="section-head">About Me</h1>
			<div className="about__media-text">
				<div className="about__text">
					<p>
						I am Hudu Hamed, a Frontend/MERN stack web developer who enjoys creating things that
						live on the internet. My interest in web development continuously grows because of the
						passion for building well-tailored web apps for our everyday activities. Here are a
						couple of projects I personally developed using React and other technologies.
					</p>
					<h3>SKILLS | TECHNOLOGIES</h3>
					<ul className="tech">
						<li>React.js</li>
						<li>Javascript/Typescript</li>
						<li>Node.js/Express</li>
						<li>REST API&apos;s</li>
						<li>Redux</li>
						<li>JWT(Json Web Token)</li>
						<li>Unit Testing (Jest)</li>
						<li>Mongodb</li>
						<li>PHP</li>
						<li>Git/Github and Curl</li>
						<li>HTML/HTML5</li>
						<li>CSS/SASS/Tailwind</li>
						<li>Clouding Computing</li>
						<li>Docker/Kubernetes</li>
					</ul>
				</div>
				<img src={hod} alt="image of Hudu" className="hod" />
			</div>
		</div>
	);
};

export default About;
