import resume from '../image/resume.pdf';

const Resume = () => {
	return (
		<div className="resume" id="resume">
			<h1 className="section-head">Resume</h1>
			<a href={resume} target="_blank" rel="noreferrer">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="88"
					height="88"
					viewBox="0 0 30.518 25.24"
					fill="#fff"
					className="folder"
				>
					<path
						id="Folder-Icon-SVG-pmsnhdjei"
						d="M1.488,25.24A.97.97,0,0,1,.6,23.882L5.407,13.029A4.648,4.648,0,0,1,9.24,10.368H28.784c1.472,0,2.135,1.193,1.484,2.661L25.416,23.983a2.1,2.1,0,0,1-1.929,1.258ZM0,18.75V2.337A2.338,2.338,0,0,1,2.337,0H8.488a3.014,3.014,0,0,1,2.378,1.173L13.2,4.218A2.984,2.984,0,0,0,15.577,5.39h6.947a2.338,2.338,0,0,1,2.337,2.337v.251a.656.656,0,0,1-.655.659H8.12a4.654,4.654,0,0,0-3.838,2.657L.9,18.94a.454.454,0,0,1-.424.28A.466.466,0,0,1,0,18.75Z"
					/>
				</svg>
			</a>
		</div>
	);
};

export default Resume;
