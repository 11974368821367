import './style.scss';

import GithubLogo from './github';
import LinkedinLogo from './linkedIn';
import CodePenLogo from './codepen';

const Footer = () => {
	return (
		<footer>
			<ul className="icons">
				<li>
					<GithubLogo />
				</li>
				<li>
					<LinkedinLogo />
				</li>
				<li>
					<CodePenLogo />
				</li>
			</ul>
			<span className="copy">&copy; 2022 H.H Fodio</span>
			<div className="footer-btn"></div>
		</footer>
	);
};

export default Footer;
