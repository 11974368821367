import Nav from './nav';
import Logo from './logo';
import NavMobile from './nav-mobile';
import { useMediaQuery } from 'react-responsive';
import './style.scss';

const Header = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
	return (
		<header>
			<Logo width={45} height={45} />
			{isMobile ? <NavMobile /> : <Nav />}
		</header>
	);
};

export default Header;
